import { SvgIcon, SvgIconProps } from '@mui/material'

export default function ExpandDrawerIcon(props: SvgIconProps) {
  return (
    <SvgIcon 
      {...props}
      width='30px'
      height='24px'
      viewBox='0 0 30 24'
    >
      <g transform="translate(15.000000, 12.000000) scale(-1, 1) translate(-15.000000, -12.000000) " id="Path">
        <path d="M28.5,24 C27.67155,24 27,23.32845 27,22.5 L27,1.5 C27,0.671565 27.67155,0 28.5,0 C29.32845,0 30,0.671565 30,1.5 L30,22.5 C30,23.32845 29.32845,24 28.5,24 Z"></path>
        <path d="M24,12 C24,12.82845 23.32845,13.5 22.5,13.5 L5.121315,13.5 L8.560665,16.93935 C9.146445,17.5251 9.146445,18.4749 8.560665,19.06065 C7.97487,19.6464 7.02513,19.6464 6.439335,19.06065 L0.439335,13.06065 C-0.146445,12.4749 -0.146445,11.5251 0.439335,10.93935 L6.439335,4.939335 C7.02513,4.353555 7.97487,4.353555 8.560665,4.939335 C9.146445,5.52513 9.146445,6.47487 8.560665,7.060665 L5.121315,10.5 L22.5,10.5 C23.32845,10.5 24,11.17155 24,12 Z"></path>
      </g>
    </SvgIcon>
  )
}