import { SvgIcon, SvgIconProps } from '@mui/material'

export default function UserIcon(props: SvgIconProps) {
  return (
    <SvgIcon 
      {...props}
      width='16px'
      height='19px'
      viewBox='0 0 16 19'
    >
      <g id="Path">
        <path d="M7.87899847,9.23796111 C9.14812445,9.23796111 10.2470875,8.78277888 11.1450153,7.88470472 C12.0429431,6.9867769 12.4981254,5.88810653 12.4981254,4.61883425 C12.4981254,3.35000092 12.0429431,2.25118421 11.144869,1.35296378 C10.2467948,0.455182262 9.14797815,0 7.87899847,0 C6.60972623,0 5.51105582,0.455182262 4.613128,1.35311008 C3.71520018,2.25103791 3.25987161,3.34985461 3.25987161,4.61883425 C3.25987161,5.88810653 3.71520018,6.9869232 4.61327431,7.88485102 C5.51134847,8.78263254 6.61016514,9.23796111 7.87899847,9.23796111 Z"></path>
        <path d="M15.9612269,14.746676 C15.9353293,14.3729905 15.882949,13.9653602 15.8058416,13.534905 C15.7280027,13.1012309 15.6277777,12.6912596 15.5078004,12.3165499 C15.3838726,11.9292573 15.2153191,11.546793 15.0069682,11.1802769 C14.7907164,10.799861 14.5367156,10.4686065 14.2516963,10.196024 C13.9536551,9.91085832 13.5887485,9.68158457 13.1667795,9.51434792 C12.7462736,9.34798909 12.2802641,9.26371234 11.781773,9.26371234 C11.5860051,9.26371234 11.396675,9.34403865 11.0310368,9.58209118 C10.8060062,9.72884394 10.5427877,9.89856796 10.2489895,10.0862886 C9.99776874,10.2463559 9.6574428,10.3963275 9.23708325,10.5321068 C8.82696564,10.6648134 8.41055656,10.7321177 7.99956105,10.7321177 C7.58856558,10.7321177 7.1723028,10.6648134 6.76174628,10.5321068 C6.34182564,10.3964738 6.0014997,10.2465022 5.75057155,10.0864349 C5.45955336,9.90047003 5.19618851,9.73074601 4.96779267,9.58194488 C4.60259341,9.34389234 4.41311702,9.26356603 4.21734916,9.26356603 C3.71871171,9.26356603 3.25284855,9.34798909 2.83248899,9.51449422 C2.41081258,9.68143827 2.04575966,9.91071201 1.7474258,10.1961703 C1.46255281,10.4688992 1.20840574,10.8000073 0.99244656,11.1802769 C0.784242004,11.546793 0.615688499,11.929111 0.491614391,12.3166962 C0.371783373,12.6914059 0.271558425,13.1012309 0.19371948,13.534905 C0.116612095,13.964775 0.0642317705,14.3725516 0.0383342361,14.7471149 C0.012875615,15.1140699 0,15.4949248 0,15.8795838 C0,16.8806628 0.318232539,17.691095 0.945772447,18.2887869 C1.56555773,18.8785779 2.38564661,19.1777896 3.38292152,19.1777896 L12.6170785,19.1777896 C13.6143534,19.1777896 14.4341497,18.8787242 15.0540812,18.2887869 C15.6817675,17.6915339 16,16.8809555 16,15.8794374 C16,15.4930227 15.9868318,15.1118752 15.9612269,14.746676 L15.9612269,14.746676 Z"></path>
      </g>
    </SvgIcon>
  )
}