import { SvgIcon, SvgIconProps } from '@mui/material'

export default function UpArrowIcon(props: SvgIconProps) {
  return (
    <SvgIcon 
      {...props}
      width='12px'
      height='9px'
      viewBox='0 0 12 9'
    >
      <g id="downArrowIcon" transform="translate(6.000000, 4.383717) scale(1, -1) translate(-6.000000, -4.383717) ">
        <path d="M11.4211436,0 L0.578826456,0 C0.0657525196,0 -0.195624769,0.826081306 0.172239563,1.31656708 L5.59339814,8.54477851 C5.81605286,8.84165148 6.1839172,8.84165148 6.40666873,8.54477851 L11.8278273,1.31656708 C12.1955948,0.826081306 11.9342175,0 11.4211436,0 Z" id="Path"></path>
      </g>
    </SvgIcon>
  )
}